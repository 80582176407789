.container {
  /* padding-top: 50px; */
}

.page-title-dark {
  width: 100%;
  font-size: 36pt;
  color: #ff5702;
  padding-left: 100px;
  margin: 150px 0px 0px 0px;
}
.page-description-dark {
  padding: 0px 100px 0px 100px;
  font-size: 20pt;
  line-height: 36pt;
  margin: 50px 0px 50px 0px;
  color: white;
}
.content-dark {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 80%;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .content-dark {
    justify-content: space-evenly;
    width: 100%;
  }
  .page-title-dark {
    margin: 100px 0px 0px 0px;
    text-align: center;
    padding: 0px;
    width: 90%;
    font-size: 30pt;
    font-weight: normal;
  }
  .page-description-dark {
    font-size: 16pt;
    font-weight: normal;
    padding: 0px;
    line-height: 24pt;
    width: 90%;
  }
}
