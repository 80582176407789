.emph {
  color: #7b59c4;
  font-weight: bold;
}

.project-container {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  border: 1px solid #7b59c4;
  border-radius: 20px;
}
.project-title {
  width: 90%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #7b59c4;
}
.project-image {
  text-align: center;
  margin-bottom: 10px;
}
.project-image img {
  max-width: 90%;
  border: 1px solid white;
}
.project-description {
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  font-size: 16pt;
}
.project-description img {
  text-align: center;
  width: 50px;
  margin: auto;
}
.code a {
  color: #7b59c4;
  text-decoration: none !important;
}
.code a:visited {
  color: #7b59c4;
  text-decoration: none !important;
}
.code a:active {
  color: #7b59c4;
  text-decoration: none !important;
}
.code a:hover {
  color: #7b59c4;
  text-decoration: none !important;
  font-weight: bold;
}
