.music-container {
  width: 100% !important;
}

.stage {
  margin: 10px;
  width: 40%;
  min-width: 200px;
  max-width: 400px;
  float: right;
  /* border-radius: 20px; */
}
.stage-left {
  margin: 10px 10px 0px 0px;
  width: 30%;
  min-width: 200px;
  max-width: 400px;
  float: left;
  /* border-radius: 20px; */
}

.songs {
  width: 90%;
  /* background-color: #ff5702; */
  margin: auto;
  margin-top: 100px;
  padding: 30px 20px 20px 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}
.song {
  width: 100%;
  justify-content: space-between;
  color: #ff5702;
  display: flex;
  /* padding-right: 30px; */
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid white;
}
.song-title {
  margin-right: 20px;
}
.white-text {
  color: white;
  margin-right: 10px;
}

@media only screen and (max-width: 900px) {
  .songs {
    width: 100%;
    font-size: 14pt;
    flex-direction: column;
    /* align-items: flex-start; */
    padding-left: 0px;
    justify-content: center;
    color: white;
    margin: 0px;
  }
  .song {
    flex-direction: column;
    justify-content: space-evenly;
    /* width: 100%; */
    margin: auto;
    border-bottom: none;
  }
  .player {
    width: 100%;
    margin-bottom: 10px;
  }
  .song-title {
    /* width: 1000px; */
  }
}
