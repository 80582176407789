.me-img {
  margin: 10px 10px 0px 10px;
  width: 40%;
  min-width: 200px;
  max-width: 500px;
  float: right;
  /* border-radius: 20px; */
}
.me-img-left {
  margin: 10px 10px 0px 0px;
  width: 35%;
  min-width: 200px;
  max-width: 500px;
  float: left;
  /* border-radius: 20px; */
}

.emph {
  color: #7b59c4;
  font-weight: bold;
}
