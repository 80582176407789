body {
  color: black;
}
.main-nav {
  background-color: black !important;
  align-self: flex-end !important;
}
.home-link {
  padding-top: 3px;
  color: #7b59c4 !important;
}
.jake {
  background-color: black;
  width: 100%;
  position: absolute !important;

  /* left: 1rem; */
}
.nav-bar {
  width: 100%;
  /* background-color: #ff5702; */
  height: 20px !important;
  /* z-index: 999; */
  /* margin-bottom: 200px !important; */
}
.brand {
  color: #7b59c4;
  font-size: 36pt;
}
.nav-links {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
  margin-right: 60px;
}
.nav-links a {
  /* font-weight: lighter; */
  text-decoration: none;
  display: block;
  margin: 20px;

  font-size: 18pt;
  color: white;
}
.nav-links a:hover {
  color: #ff5702 !important;
}
.nav-links a:active {
  color: #ff5702 !important;
}

@media only screen and (max-width: 600px) {
  .nav-links {
    justify-content: center;
  }
  .nav-links a {
    /* font-size: 12pt; */
    margin: 15px;
  }
}
